import React, { useState, useEffect } from 'react'

import { Row, Col, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { Field } from 'redux-form'
import styled from 'styled-components'

import CloseBorderedIcon from '../../../../assets/icons/cancel_border.svg'
import { ValidateDepartureStock } from '../../../../infra/requests/DepartureRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import NumberIncrementInput from '../../../../shared/components/inputs/NumberIncrementInput'
import AddProductInput from '../../../../shared/components/inputs/Products/AddProductInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableInput,
  TextTooltip,
  TableText,
  ListCheck
} from '../../../../shared/components/table/CustomTableStyles'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import { ErrorColor } from '../../../../shared/styles/_colors'
import {
  PageSubtitle,
  Margin,
  AvailableTooltip
} from '../../../../shared/styles/BasicStyles'

const AvailableContainer = styled.div`
  position: relative;
  width: 100%;
`

const DepartureProducts = ({
  fields,
  originWarehouse,
  destinyWarehouseId,
  disabled,
  translate,
  selectedOrders,
  selectedFilters
}) => {
  const [selected, setSelected] = useState([])
  const [isLoadingList, setIsLoadingList] = useState(false)
  const products = fields.getAll() || []

  const addTableProducts = async (list) => {
    setIsLoadingList(true)
    if (originWarehouse && originWarehouse.warehouseId) {
      const { data } = await ValidateDepartureStock({
        warehouseId: originWarehouse.warehouseId,
        skus: list.map((p) => ({
          productId: p.productId,
          productWidthId: p.productCombination[0].productWidth.productWidthId,
          sizeId: p.sizeId,
          colorId: p.productCombination[0].color.colorId,
          units: p.productCombination[0].size.sizeId
        }))

      })

      list.forEach((product) => {
        const found = products.find(
          (prod) => prod.reference === product.productCombination[0].reference
        )

        const availability = data.find(
          (d) =>
            d.productId == product.productId &&
            d.productWidthId == product.productWidthId &&
            d.sizeId == product.sizeId &&
            d.colorId == product.colorId
        )

        if (!found) {
          fields.push({
            ...product,
            departQty: product.productCombination[0].quantity,
            availableStock: parseInt(availability?.available || 0, 10)
          })
        }
      })
    } else {
      list.forEach((product) => {
        const found = products.find(
          (prod) => prod.reference === product.reference
        )
        if (!found) {
          fields.push({
            ...product,
            departQty: product.quantity
          })
        }
      })
    }
    setIsLoadingList(false)
  }

  useEffect(() => {
    addTableProducts(selectedOrders)
  }, [selectedOrders])

  const showError = (product) => {
    if (product.orderQuantity > product.stock) {
      return true
    }
    return false
  }

  const handleRemove = () => {
    selected
      .sort((a, b) => b - a)
      .forEach((index) => {
        fields.remove(index)
      })
    setSelected([])
  }

  const handleCheck = (e, index) => {
    const copy = [...selected]
    if (e.target.checked) {
      copy.push(index)
      setSelected(copy)
    } else {
      setSelected(copy.filter((i) => i !== index))
    }
  }

  const renderTotal = (product, destinyWarehouseUnitsAvailable, originWarehouseUnitsAvailable) => {
    const orderQuantity = parseFloat(product?.quantity || product?.orderQuantity || 0)
    const stockDestiny = destinyWarehouseUnitsAvailable || 0
    const total = orderQuantity + stockDestiny || 0

    const showAvailableError = orderQuantity > originWarehouseUnitsAvailable
    return (
      <AvailableContainer>
        {total}
        {showAvailableError && (
          <Tooltip
            title={translate('NO_AVAILABLE_STOCK')}
            color={ErrorColor}
          >
            <AvailableTooltip $error />
          </Tooltip>
        )}
      </AvailableContainer>
    )
  }

  const renderAvailableInput = (product, originWarehouseUnitsAvailable) => {
    const orderQuantity = parseFloat(product?.quantity || 0)
    const showAvailableError = orderQuantity > originWarehouseUnitsAvailable
    return (
      <AvailableContainer>
        {originWarehouseUnitsAvailable}
        {showAvailableError && (
          <Tooltip
            title={translate('NO_AVAILABLE_STOCK')}
            color={ErrorColor}
          >
            <AvailableTooltip $error />
          </Tooltip>
        )}
      </AvailableContainer>
    )
  }

  const getWarehouseData = (productCombination, warehouseId) =>
    productCombination?.productCombinationWarehouse?.find(
      (combination) => combination.warehouseId === warehouseId
    ) ?? {}

  const getWarehouseStock = (product) => {
    const { size } = product || {}
    const sizeId = size?.sizeId ?? 0

    if (!sizeId) {
      const {
        stock,
        stockReserved,
        stockDestiny,
        stockReservedDestiny
      } = product

      return {
        originWarehouseUnitsAvailable: stock || 0,
        originWarehouseUnitsReserved: stockReserved || 0,
        destinyWarehouseUnitsAvailable: stockDestiny || 0,
        destinyWarehouseUnitsReserved: stockReservedDestiny || 0
      }
    }
    const productCombination = product.productCombination?.find((p) => p.sizeId === sizeId)

    const originData = getWarehouseData(productCombination, originWarehouse?.warehouseId)
    const destinyData = getWarehouseData(productCombination, Number(destinyWarehouseId))

    return {
      originWarehouseUnitsAvailable: originData.unitsAvailable ?? 0,
      originWarehouseUnitsReserved: originData.unitsReserved ?? 0,
      destinyWarehouseUnitsAvailable: destinyData.unitsAvailable ?? 0,
      destinyWarehouseUnitsReserved: destinyData.unitsReserved ?? 0
    }
  }

  return (
    <>
      <PageSubtitle>Products</PageSubtitle>
      <Margin size={20} />
      <Row>
        <Col xs={24}>
          <BaseButton
            disabled={selected.length <= 0 || disabled}
            type='secondary'
            onClick={handleRemove}
            imagePath={CloseBorderedIcon}
            auto
          >
            Remove
          </BaseButton>
        </Col>
      </Row>
      <Margin size={20} />
      <TableRow $header>
        <ListCol xs={14} />
        <ListCol xs={4}><TableTitle text='Existing Quantity' /></ListCol>
        <ListCol xs={6}><TableTitle text='Send Quantity' /></ListCol>
      </TableRow>
      <TableRow $header>
        <ListCol xs={1} />
        <ListCol xs={3}>
          <TableTitle text='Reference' />
        </ListCol>
        <ListCol xs={3}>
          <TableTitle text='EAN Code' />
        </ListCol>
        <ListCol xs={3}>
          <TableTitle text='Description' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text='Color' />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text='Size' />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text='Width' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text='Available' abbr='Available' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text='Reserved' abbr='Reserved' />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text='Quantity' abbr='Quantity' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text='Available' abbr='Available' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text='Reserved' abbr='Reserved' />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text='Total' abbr='Total' />
        </ListCol>
      </TableRow>

      <TableRow>
        <ListCol xs={1} />
        <ListCol xs={3}>
          <TableInput $first>
            <AddProductInput
              placeholder='Search1'
              onSelectValues={addTableProducts}
              warehouseId={originWarehouse?.warehouseId}
              field='ref'
              disabled={!selectedFilters.originWhSelected || !selectedFilters.destinyWhSelected}
            />
          </TableInput>
        </ListCol>
        <ListCol xs={3} />
        <ListCol xs={3}>
          <TableInput $first>
            <AddProductInput
              placeholder='Search'
              dataLabel='productTranslation'
              onSelectValues={addTableProducts}
              field='name'
              disabled={!selectedFilters.originWhSelected || !selectedFilters.destinyWhSelected}
            />
          </TableInput>
        </ListCol>
      </TableRow>

      {isLoadingList ? (
        <TableRow>
          <BaseLoading margin={0} padding={30} />
        </TableRow>
      ) :
        products.map((product, index) => {
          const {
            originWarehouseUnitsAvailable,
            originWarehouseUnitsReserved,
            destinyWarehouseUnitsAvailable,
            destinyWarehouseUnitsReserved
          } = getWarehouseStock(product)

          return (
            <TableRow key={index}>
              <ListCol xs={1}>
                {!product.orderB2bitemId && !product.orderSupplierId && (
                  <ListCheck
                    disabled={disabled}
                    checked={selected.includes(index)}
                    onChange={(e) => handleCheck(e, index)}
                  />
                )}
              </ListCol>
              <ListCol xs={3}>
                <TextTooltip text={product?.productCombination[0]?.reference || '-'} />
              </ListCol>
              <ListCol xs={3}>
                <TextTooltip text={product?.productCombination[0]?.barcode || '-'} />
              </ListCol>
              <ListCol xs={3}>
                <TextTooltip
                  text={
                    <ActiveTranslation
                      value={product?.productTranslation}
                      tag='name'
                    />
                  }
                />
              </ListCol>
              <ListCol xs={2}>
                <TextTooltip
                  text={
                    <ActiveTranslation
                      value={product?.productCombination[0]?.color?.colorTranslation || '-'}
                      tag='name'
                    />
                  }
                />
              </ListCol>
              <ListCol xs={1}>
                <TextTooltip text={product?.size?.nameEu || product?.productCombination[0]?.size?.nameEu || '-'} />
              </ListCol>
              <ListCol xs={1}>
                <TextTooltip text={
                  <ActiveTranslation
                    value={product?.productCombination[0]?.productWidth?.productWidthTranslation || '-'}
                    tag='name'
                  />
                }
                />
              </ListCol>
              <ListCol xs={2}>
                <TableText>
                  {renderAvailableInput(product, originWarehouseUnitsAvailable)}
                </TableText>
              </ListCol>

              <ListCol xs={2}>
                <TableText>
                  {originWarehouseUnitsReserved}
                </TableText>
              </ListCol>

              <ListCol xs={1}>
                <TableText>
                  <Field
                    showError={() => showError(product)}
                    component={NumberIncrementInput}
                    defaultValue={product?.orderQuantity || 0}
                    name={`${fields.name}[${index}].quantity`}
                    onChange={(e, newValue) => {
                      const updatedProduct = { ...product, orderQuantity: newValue, departQty: parseInt(newValue, 10) }
                      fields.remove(index)
                      fields.insert(index, updatedProduct)
                    }}
                  />
                </TableText>
              </ListCol>
              <ListCol xs={2}>
                <TableText>
                  {destinyWarehouseUnitsAvailable}
                </TableText>
              </ListCol>
              <ListCol xs={2}>
                <TableText>
                  {destinyWarehouseUnitsReserved}
                </TableText>
              </ListCol>
              <ListCol xs={1}>
                <TableText>
                  {renderTotal(product, destinyWarehouseUnitsAvailable, originWarehouseUnitsAvailable)}
                </TableText>
              </ListCol>
            </TableRow>
          )
        })}
    </>
  )
}

DepartureProducts.propTypes = {
  fields: PropTypes.object.isRequired,
  originWarehouse: PropTypes.object,
  destinyWarehouseId: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  selectedOrders: PropTypes.array.isRequired,
  selectedFilters: PropTypes.object.isRequired
}

DepartureProducts.defaultProps = {
  originWarehouse: {},
  destinyWarehouseId: undefined
}

export default withLocalize(DepartureProducts)
