import moment from 'moment'

import { ValidateDepartureStock } from '../../../infra/requests/DepartureRequests'

const validateBoxParams = (errors, data, label) => {
  if (data.generatedKit && data.error && !data.validated) {
    errors.push(`Please remove package ${data.realIndex || label} or acknowledge that not all products for the kit will be sent to the employee`)
  }

  if (data.isKit) {
    if (!data.employeeId || data.employeeId === '') {
      errors.push(`Define the employee for the kit ${data.realIndex || label}`)
    }
    if (!data.finalAddressId || data.finalAddressId === '') {
      errors.push(`Define the final address for the kit ${data.realIndex || label}`)
    }
  }
  if (!data.deliveryAddressId || data.deliveryAddressId === '') {
    errors.push('Delivery Address is mandatory ')
  }
}

export const ValidateDepartureData = (formValues) => {
  const errors = []
  const products = []

  if (!formValues.movementTypeId || formValues.movementTypeId === '') {
    errors.push('Define the type of departure')
  }

  if (!formValues.originId || formValues.originId === '') {
    errors.push('Define the origin warehouse')
  }

  if (!formValues.departureDate || formValues.departureDate === '') {
    errors.push('Define the date of the departure')
  }

  if (formValues.movementTypeId === '5') {
    if (!formValues.supplierId || formValues.supplierId === '') {
      errors.push('Select a supplier first')
    }
    if (!formValues.orderSupplierId || formValues.orderSupplierId === '') {
      errors.push('Select a supplier order first')
    }
  }

  if (
    formValues.movementTypeId === '6' ||
    formValues.movementTypeId === '7' ||
    formValues.movementTypeId === '8'
  ) {
    if (!formValues.clientId || formValues.clientId === '') {
      errors.push('Select a client first')
    }
    if (!formValues.orderB2bid || formValues.orderB2bid === '') {
      errors.push('Select a client order first')
    }
    if (!formValues.sellerId || formValues.sellerId === '') {
      errors.push('Select the seller of the order')
    }
  }

  if (formValues.movementTypeId === '11') {
    if (!formValues.clientId || formValues.clientId === '') {
      errors.push('Select a client first')
    }
    if (!formValues.orderB2bid || formValues.orderB2bid === '') {
      errors.push('Select a client order first')
    }
    if (!formValues.sellerId || formValues.sellerId === '') {
      errors.push('Select the seller of the order')
    }
  }

  if (!formValues.products || formValues.products.length === 0) {
    errors.push('The product list is empty')
  } else {
    formValues.products.forEach((prod) => {
      // REMOVED TO ALLOW TO NOT SEND DOME ITEMS
      // if (!prod.departQty || parseInt(prod.departQty, 10) < 1) {
      //   errors.push(`Define the quantity to send for ${prod.reference}`)
      // }

      // if (
      //   parseInt(prod.orderQuantity || 0, 10) >
      //   parseInt(prod.availableStock || 0, 10)
      // ) {
      //   errors.push(`Order Quantity cant be bigger than the Available Stock  - Reference: ${prod.reference}`)
      // }

      if (
        parseInt(prod.orderQuantity || 0, 10) <
        parseInt(prod.departQty || 0, 10)
      ) {
        errors.push(`Amount on send Quantity is bigger than the Order Quantity  - Reference: ${prod.reference}`)
      }

      products.push({
        ...prod,
        left: prod.departQty,
        box: undefined
      })
    })
  }
  return { errors, products }
}

// This function validates the stock availability for a list of products in a specific warehouse
export const ValidateStock = async (warehouseId, products) => {
  // Construct payload containing warehouse ID and products with their departure quantities
  const payload = {
    warehouseId,
    skus: products.map((p) => ({
      productId: p.productId,
      productWidthId: p.productWidthId,
      sizeId: p.sizeId,
      colorId: p.colorId,
      units: p.departQty // Departure quantity for each product
    }))
  }

  // Call an asynchronous function to validate the stock availability
  const { data } = await ValidateDepartureStock(payload)

  // Initialize a variable to track if departure is possible based on available stock
  let possible = true

  // Check if validation data is returned and is an array
  if (data && Array.isArray(data)) {
    // Iterate over each product in the validation data
    data.forEach((p) => {
      // Find the index of the product in the original products array
      const index = products.findIndex(
        (x) =>
          x.productId === p.productId &&
          x.productWidthId === p.productWidthId &&
          x.sizeId === p.sizeId &&
          x.colorId === p.colorId
      )

      // If the product is found in the original products array
      if (index > -1) {
        // Check if the departure quantity is less than the available stock
        const minor =
          parseInt(products[index].departQty, 10) <
          parseInt(p.available, 10)

        // Update the product with available stock and a boolean indicating if departure quantity is minor
        products[index].available = p.available // Available stock for the product
        products[index].minor = minor // Boolean indicating if departure quantity is minor

        // If the departure quantity is minor, update possible to false
        if (minor) {
          possible = false
        }
      }
    })
  }

  // Return a boolean indicating if departure is possible based on available stock
  return possible
}

export const ValidatePicking = (products, boxes) => {
  const errors = []

  let assigned = true
  let overReach = false
  products.forEach((prod) => {
    if (prod.left > 0) assigned = false
    if (prod.left < 0) overReach = true
  })
  if (!assigned) {
    errors.push('There are still products not assign to boxes')
  }

  if (overReach) {
    errors.push('The departure quantities are not enough to generate the necessary kits')
  }

  if (boxes && boxes.length) {
    boxes.forEach((box) => {
      validateBoxParams(errors, box, 'box')
    })
  }

  return { errors }
}

export const validateTransport = (
  formValues,
  selectedCarrier,
  selectedService
) => {
  const errors = []
  if (!selectedCarrier || selectedCarrier === '') {
    errors.push('Define a carrier')
  }
  if (
    !selectedCarrier?.hasIntegration &&
    (!formValues.estimatedShippingDate ||
      formValues.estimatedShippingDate === '')
  ) {
    errors.push('Define the Estimated Delivery Date')
  }
  if (
    !selectedCarrier?.hasIntegration &&
    (!formValues.estimatedShippingValue ||
      formValues.estimatedShippingValue === '')
  ) {
    errors.push('Define the Estimated Shipping Value')
  }

  if (!formValues.shippingValue || formValues.shippingValue === '') {
    errors.push('Define the Shipping Value')
  }

  if (!formValues.shippingContents || formValues.shippingContents === '') {
    errors.push('Define the Shipping Contents')
  }

  if (
    selectedCarrier?.hasIntegration &&
    (!selectedService || selectedService.length != 1)
  ) {
    errors.push('Define a Service')
  }
  if (
    selectedCarrier?.hasIntegration &&
    (!formValues.trackingNumber || !formValues.trackingNumber.length)
  ) {
    errors.push('Define tracking number')
  }

  return errors
}

const createBox = (boxes, item, idBox, idParent) => {
  const itemsInBox = []
  if (item.items && item.items.length) {
    item.items.forEach((product) =>
      itemsInBox.push({
        productId: product.productId?.toString(),
        sizeId: product.sizeId?.toString(),
        colorId: product.colorId?.toString(),
        productWidthId: product.productWidthId?.toString(),
        qtyInBox: product.quantity?.toString()
      })
    )
  }

  const boxesObj = {
    boxId: idBox?.toString(),
    boxParentId: idParent?.toString() || '',
    level: item.type === 'CONTAINER' ? 3 : item.type === 'LOT' ? 2 : 1,
    isKit: item.isKit?.toString() || 'false',
    ean128: item.ean?.toString(),
    items: itemsInBox.length?.toString(),
    weight: item.weight?.toString(),
    length: item.length?.toString(),
    height: item.height?.toString(),
    depth: item.depth?.toString(),
    itemsInBox
  }

  if (item.employeeId != '' && item.employeeId != undefined) {
    boxesObj.employeeId = item.employeeId
  }

  boxes.push(boxesObj)
}

export const ValidateShipping = (formValues) => {
  const errors = []
  const boxes = []
  const products = []
  const shipment = []
  let idBox = 1
  let idParent
  let idParentContainer

  if (formValues.shipping && formValues.shipping.length) {
    formValues.shipping.forEach((shipping) => {
      if (!shipping.carrier) {
        errors.push(
          `Define ${shipping.deliveryContactDetail.name} transportation`
        )
      }
      if (!shipping.estimatedShippingDate) {
        errors.push(
          `Define ${shipping.deliveryContactDetail.name} estimated shipping date`
        )
      }
      if (!shipping.estimatedShippingValue) {
        errors.push(
          `Define ${shipping.deliveryContactDetail.name} estimated shipping value`
        )
      }

      // boxes
      if (shipping.items && shipping.items.length) {
        shipping.items.forEach((item) => {
          if (item.type === 'BOX' || item.isKit) {
            createBox(boxes, item, idBox, idParent)
            idBox++
          }

          if (item.type === 'LOT') {
            createBox(boxes, item, idBox, idParent)
            idParent = idBox
            idBox++

            if (item.boxes && item.boxes.length) {
              item.boxes.forEach((box) => {
                createBox(boxes, box, idBox, idParent)
                idBox++
              })
            }
            idParent = undefined
          }

          if (item.type === 'CONTAINER') {
            createBox(boxes, item, idBox, idParent)
            idParentContainer = idBox
            idBox++

            if (item.lots && item.lots.length) {
              item.lots.forEach((lot) => {
                createBox(boxes, lot, idBox, idParentContainer)
                idParent = idBox
                idBox++

                if (lot.boxes && lot.boxes.length) {
                  lot.boxes.forEach((box) => {
                    createBox(boxes, box, idBox, idParent)
                    idBox++
                  })
                }

                idParent = undefined
              })
            }

            idParentContainer = undefined
          }
        })
      }

      // shipment
      shipment.push({
        boxIds: boxes.map((box) => box.boxId),
        carrierId: shipping?.carrier?.carrierID,
        shippingContents: shipping.shippingContents || '', // novo campo tipo string: 'Nome/descrição do shipment
        shippingValue: shipping.shippingValue?.replace(',', '.') || '', // Introduzido no modal pelo utilizador, É obrigatório preencher mas pode ser 0 - decimais, têm de vir com ponto em vez de vírgula
        incoterms:
          shipping.incoterms == 0
            ? 0
            : parseInt(shipping.incoterms, 10) || '', // valor do dropdown incoterms (novo pedido GetIncotermsDrop) enviar o id em int
        name: shipping.deliveryContactDetail.name || '',
        telephone: shipping.deliveryContactDetail?.contact?.phone || '',
        addressLine1:
          shipping.deliveryContactDetail?.contact?.addressLine1 || '',
        addressLine2:
          shipping.deliveryContactDetail?.contact?.addressLine2 || '',
        addressLine3:
          shipping.deliveryContactDetail?.contact?.addressLine3 || '',
        streetNumber:
          shipping.deliveryContactDetail?.contact?.streetNumber || '',
        zipCode: shipping.deliveryContactDetail?.contact?.zipCode || '',
        city: shipping.deliveryContactDetail?.contact?.city || '',
        region: shipping.deliveryContactDetail?.contact?.region || '',
        countryId:
          shipping.deliveryContactDetail?.contact?.countryId || '1',
        // O shippingDate está em null, mas tem que vir preenchido
        shippingDate: shipping.shippingDate || moment(),
        trackingNumber:
          shipping.carrier == 3 ? shipping.trackingNumber : '', // Só é possível preencher em caso de a transportadora ser DHL, caso contrário trackingnumber é vazio
        shipmentCost: shipping.estimatedShippingValue || '', // recebido pelo getRates caso seja DHL, se não é introduzido pelo utilizador - decimais, têm de vir com ponto em vez de vírgula
        shipmentType:
          (shipping.carrier &&
            shipping.carrier.hasIntegration &&
            shipping.rate?.length > 0 &&
            shipping.rate[0].key?.toString()) ||
          '',
        destinationWarehouseId:
          shipping.deliveryContactDetailId?.toString() // has to be a string,
      })

      // products
      if (formValues.products && formValues.products.length) {
        formValues.products.forEach((product) => {
          products.push({
            productId: product.productId?.toString() || '',
            sizeId: product.sizeId?.toString() || '',
            colorId: product.colorId?.toString() || '',
            productWidthId: product.productWidthId?.toString() || '',
            departQty: product.departQty?.toString() || ''
          })
        })
      }
    })
  }

  return { errors, boxes, products, shipment }
}
